import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

const HomeIndex = ({ data: { site } }) => (
  <Layout>
    <Helmet>
      <title>{site.siteMetadata.title}</title>
      <meta name="description" content={site.siteMetadata.description} />
    </Helmet>

    <div id="main">
      <section id="one">
        <header className="major">
          <h2>Hello and welcome.</h2>
        </header>
        <p>
          Hi, thanks for dropping by. I am a web developer currently living in
          the South West of England. My interests range from technology to
          programming. I also enjoy the great outdoors and gardening.
        </p>
        <p>
          If you would like to hire me, vist{' '}
          <a href="https://www.wessexdigitalsolutions.co.uk">
            Wessex Digital Solutions
          </a>{' '}
          for more details.
        </p>
        <p>
          You can go to <a href="https://duncanbrown.dev">duncanbrown.dev</a> to
          read my blog.
        </p>
        <p>
          If you’d like to get in touch, feel free to say hello through any of
          the social links.
        </p>
        <p>
          If you'd like an easier way to shop locally, you can visit{' '}
          <a href="https://about.mylocal.gifts/">mylocal.gifts</a>. This is a
          network of shop local online marketplaces.
        </p>
      </section>
    </div>
  </Layout>
)

export default HomeIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
